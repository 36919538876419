// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // token: "89436df0af4490998c4f89f2b80c02ea6e110600",
  // baseUrl: "https://onenow-bo-hotfix.i2go.io/api/",

  token: '81f48557779e15156ed1401410ec158a60d0a0f5',
  baseUrl: 'https://onenow-bo.i2go.io/api/',
  stripeKey: "pk_test_PQ9UxzuzWISp5OoXbLmhWVU7",
  product_uuid: "752aea42-1b1d-4d62-8dab-1ef425ad9463"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
