import {Component, HostListener, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpClient} from '@angular/common/http';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {forkJoin} from 'rxjs';
import {isArray} from 'rxjs/internal-compatibility';
import {MAT_STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {MatHorizontalStepper, MatIconRegistry, MatSnackBar, MatStepper, MatVerticalStepper, VERSION} from '@angular/material';
import {isNull} from 'util';
import {FormService} from './services/form.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {ApiService} from './services/api.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../environments/environment';


export class CustomValidation {

    static MatchEmail(control: AbstractControl) {
        if (!control.get('email_primary') || !control.get('confirmEmail')) {
            return null;
        }

        if (control.get('email_primary').value !== control.get('confirmEmail').value) {
            control.get('confirmEmail').setErrors({MatchPassword: true});
        } else {
            return null;
        }
    }

    static AtLeastOneSelected(control: AbstractControl) {
        if (control.get('selected insurances') && !Object.keys(control.get('selected insurances').value).map(val => !!control.get('selected insurances').value[val]).includes(true)) {
            control.get('selected insurances').setErrors({incorrect: true});
        }
        return null;
    }
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [{provide: MAT_STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}}],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({transform: 'translateY(-100%)', opacity: 0, height: '0px'}),
                animate('300ms ease-in', style({transform: 'translateY(0%)', opacity: 1, height: '100px'}))
            ]),
            transition(':leave', [
                animate('300ms ease-in', style({transform: 'translateY(-100%)', opacity: 0, height: '0px'}))
            ])
        ])
    ]
})

export class AppComponent implements OnInit {
    version = VERSION;

    warning = false;

    formGroup: FormGroup = new FormGroup({});
    formData: any[] = [];

    /** false for testing purpose only */
    isLinear = true;
    inProgress = true;
    price: number;
    visible = false;
    autocomplete = 'off';
    disableOffer = false;
    picture_url = 'assets/OneNowInsurance/img/1NOW.jpg';

    protected pageYOffset = 40;

    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
        this.visible = window.pageYOffset <= this.pageYOffset;
    }

    constructor(private route: ActivatedRoute, private httpService: HttpClient, public snackBar: MatSnackBar, private formService: FormService, private apiService: ApiService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
        this.apiService.authenticate();
        this.apiService.formGroup = this.formGroup;
        this.apiService.formService = this.formService;
        this.apiService.formData = this.formData;
        this.apiService.profile_picture.subscribe(url => this.picture_url = url);

        this.formService.warning.subscribe((showWarning: boolean) => this.warning = showWarning);

    }

    ngOnInit(): void {
        setTimeout(() => {
            this.route.queryParamMap.subscribe(params => {
                const referrer_uuid = params.get('referrer_uuid');
                if (referrer_uuid) {
                    this.apiService.getAgent(referrer_uuid);
                }
            });
        }, 500);

        forkJoin(
            this.httpService.get('./assets/OneNowInsurance/CustomerDetails.json'),
            this.httpService.get('./assets/OneNowInsurance/InsuranceDetails.json'),
            this.httpService.get('./assets/OneNowInsurance/InsuranceSummary.json'),
            this.httpService.get('./assets/OneNowInsurance/InsuranceContract.json'),
            this.httpService.get('./assets/OneNowInsurance/Finale.json'),
        ).subscribe(
            sites => sites.map(data => this.initForm(data as string [])),
            (err: HttpErrorResponse) => console.log(err.message)
        );

        this.formService.final_amount.subscribe(price => {
            this.price = price;
            this.togglePriceButton();
        });

        this.registerIcons();

        setInterval(() => this.apiService.authenticate(), 120000);
    }

    private registerIcons() {
        this.matIconRegistry.addSvgIcon('wheel', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/MF.svg'));
        this.matIconRegistry.addSvgIcon('key', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/Schlüssel.svg'));
        this.matIconRegistry.addSvgIcon('hunter', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/Jäger.svg'));
        this.matIconRegistry.addSvgIcon('alpine', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/Ski Bergführer.svg'));
        this.matIconRegistry.addSvgIcon('horses', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/Pferde.svg'));
        this.matIconRegistry.addSvgIcon('teacher', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/teacher.svg'));

        this.matIconRegistry.addSvgIcon('danger', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/umbrella.svg'));
        this.matIconRegistry.addSvgIcon('income', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/Rechnung.svg'));
        this.matIconRegistry.addSvgIcon('pipe', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/pipe.svg'));

        this.matIconRegistry.addSvgIcon('thief', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/robbery.svg'));
        this.matIconRegistry.addSvgIcon('window', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/glass.svg'));
        this.matIconRegistry.addSvgIcon('rings', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/ring.svg'));
        this.matIconRegistry.addSvgIcon('warning', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/allrisk.svg'));

        this.matIconRegistry.addSvgIcon('shield', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/shield.svg'));
        this.matIconRegistry.addSvgIcon('gebaeude', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/gebaeude.svg'));
        this.matIconRegistry.addSvgIcon('room', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/room.svg'));
        this.matIconRegistry.addSvgIcon('reise', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/reise.svg'));

        this.matIconRegistry.addSvgIcon('police', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/Versicherungspolice.svg'));
        this.matIconRegistry.addSvgIcon('download', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/download.svg'));
        this.matIconRegistry.addSvgIcon('cancellation', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/Kündigungsschreiben.svg'));
        this.matIconRegistry.addSvgIcon('account', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/Zugangsdaten.svg'));
        this.matIconRegistry.addSvgIcon('invoice', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/OneNowInsurance/icons/Rechnung.svg'));
    }

    openCheckout(stepper: MatStepper) {
        const service = this.apiService;
        if (this.getValue('contract.insurance data.payment_type') === 'credit_card') {
            const handler = (<any>window).StripeCheckout.configure({
                image: 'assets/OneNowInsurance/img/1NOW.jpg',
                key: environment.stripeKey,
                locale: 'de-CH',
                currency: 'CHF',
                email: this.apiService.identifier || 'demo@demo.demo',
                token: function (token: any) {
                    service.createCreditPayment(token);
                    stepper.next();
                }
            });
            handler.open({name: 'Versicherungsprämie', description: 'Bezahlung', amount: this.formService.total_payable_amount * 100 || 0});
        } else {
            service.createBillingPayment();
            stepper.next();
        }
    }

    sendOffer() {
        this.apiService.sendOffer();
        this.disableOffer = true;
        setTimeout(() => this.disableOffer = false, 3000);
    }

    private togglePriceButton() {
        this.visible = true;
        setTimeout(() => this.visible = window.pageYOffset <= this.pageYOffset, 3000);
    }

    goToStart(horizontal_stepper: MatHorizontalStepper, vertical_stepper: MatVerticalStepper) {
        horizontal_stepper.selectedIndex = 0;
        vertical_stepper.selectedIndex = 0;
    }

    /**
     * scroll to top
     */
    scrollToTop() {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    /**
     * scroll to bottom
     */
    scrollToBottom() {
        window.scrollTo(0, document.body.scrollHeight);
    }

    private scrollTo(id, valid: boolean) {
        const elem = document.getElementById(id);
        if (elem && valid) {
            setTimeout(() => elem.scrollIntoView({block: 'start', inline: 'nearest', behavior: 'smooth'}), 500);
        }
    }

    private initForm(data: any[]) {
        /** set form data */
        this.formData.push(data);

        /**  create form group */
        const controls = new FormGroup({}, [CustomValidation.AtLeastOneSelected]);
        data['categories'].map(category => {
            /** create sub form group */
            if (isArray(category['fields'])) {
                const _controls = new FormGroup({}, [CustomValidation.MatchEmail]);
                category['fields'].map(fields => _controls.addControl(fields['name'], new FormControl(fields['value'])));
                controls.addControl(category['name'], _controls);
            }
            controls.addControl(category['name'], new FormControl(category['value']));
        });
        this.formGroup.addControl(data['name'], controls);

        /**  show form */
        setTimeout(() => this.inProgress = false, 1000);
    }

    private onSubmit(formGroup: FormGroup, category?: Object) {
        if (isNull(formGroup.value)) {
            return true;
        }

        if (formGroup.invalid && this.isLinear) {
            console.log('FormGroup Invalid.');
            Object.keys(formGroup.controls).forEach(controls => formGroup.get(controls).markAsTouched());
            this.openSnackBar('Bevor es weitergeht, füllen Sie bitte alle Pflichtfelder korrekt aus.', 'OK');
        } else {

            if (formGroup.value['email_primary']) {
                if (this.apiService.identifier !== formGroup.value['email_primary']) {
                    this.apiService.identifier = formGroup.value['email_primary'];
                    this.apiService.resetApiIds();
                }
            }

            if (category['callFunction']) {
                this.apiService[category['callFunction']](category);
                formGroup.markAsPristine();
            }
        }
    }

    private getValue(val) {
        return this.apiService.getNested(this.formGroup.value, val) || null;
    }

    private isVisible(dependencies: any[], formGroup: FormGroup) {
        if (!dependencies || isNull(dependencies) || !this.isLinear) {
            return true;
        }

        if (dependencies.map(dependency => Array.isArray(dependency) ? !dependency.map(dep => this.getValue(dep)).includes(false) : this.getValue(dependency)).includes(true)) {
            return true;
        } else if (formGroup.touched || formGroup.invalid) {
            formGroup.reset(false, {onlySelf: false});
            // Object.keys(formGroup.value).map(item => this.formService.removePriceItemByKey(item));
            if (formGroup.invalid) {
                Object.keys(formGroup.controls).forEach(controls => formGroup.get(controls).setErrors(null));
            }
        }
        return false;
    }

    private openSnackBar(message: string, action: string, duration = 3000) {
        this.snackBar.open(message, action, {duration: duration});
    }
}
