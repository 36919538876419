import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';

@Component({
    selector: 'app-phone',
    templateUrl: './phone-number.component.html',
    styleUrls: ['./phone-number.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneNumberComponent),
            multi: true
        }
    ]
})

export class PhoneNumberComponent implements ControlValueAccessor {
    @Input() required: boolean;
    @Input() options: any[];
    @Input() formControl: FormControl;

    isVisible: boolean = false;

    value: string;
    disabled: boolean;
    onChange: (value) => void;
    onTouched: () => void;

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: string): void {
        const validators = [Validators.pattern('^(((((((00|\\+)[1-9][0-9][ \\-/]?)|0)[1-9][0-9]{1,4})[ \\-/]?)|((((00|\\+)[1-9][0-9]\\()|\\(0)[1-9][0-9]{1,4}\\)[ \\-/]?))[0-9]{1,7}([ \\-/]?[ 0-9]{1,6})?)$')];
        if (this.required) validators.push(Validators.required);
        this.formControl.setValidators(validators);
        this.value = value || '';
    }
}
