import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormService} from '../../services/form.service';
import {ApiService} from '../../services/api.service';

@Component({
    selector: 'app-download',
    templateUrl: './download.component.html',
    styleUrls: ['./download.component.scss']
})

export class DownloadComponent implements OnInit {
    @Input() options: any;
    @Input() formGroup: FormGroup;

    link: string;

    constructor(private service: ApiService) {
        this.service.pdf_file.subscribe(link => this.link = link);
    }

    ngOnInit() {

    }
}
