import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FormService} from '../../services/form.service';

@Component({
    selector: 'app-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioComponent),
            multi: true
        }
    ]
})

export class RadioComponent implements ControlValueAccessor {
    @Input() required: boolean;
    @Input() options: any[];
    @Input() formControl: FormControl;

    constructor(private formService: FormService) {

    }

    value: string | boolean;
    disabled: boolean;
    onTouched: () => void;
    onChange: any = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: string): void {
        this.value = value === '' ? null : value;
    }

    toggleWarning(val) {
        if (this.options['warning'] === true) {
            val ? this.formService.showWarning(this.options['key']) : this.formService.hideWarning(this.options['key']);
        }
    }
}
