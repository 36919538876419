import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ValidatorFn,
  Validators
} from '@angular/forms';
import * as _moment from 'moment';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MY_FORMATS} from '../components.component';
import {Moment} from 'moment';

const moment = _moment;

export function dateValidatorMax(maxDate: Moment): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value > maxDate ? {'forbiddenDate': {value: control.value}} : null;
  };
}

export function dateValidatorMin(minDate: Moment): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value && control.value < minDate ? {'forbiddenDate': {value: control.value}} : null;
  };
}

@Component({
  selector: 'app-birthday',
  templateUrl: './birthday.component.html',
  styleUrls: ['./birthday.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BirthdayComponent),
      multi: true
    },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class BirthdayComponent implements ControlValueAccessor, OnInit {
  @Input() required: boolean;
  @Input() options: any[];
  @Input() formControl: FormControl;

  isVisible: boolean = false;

  value: Moment;
  minAge: number;
  disabled: boolean;
  maxDate = moment().hours(0).minutes(0).seconds(0).milliseconds(0);
  minDate = moment('1900-01-01T00:00:00.000Z');
  onChange: (value) => void;
  onTouched: () => void;

  ngOnInit() {
    this.minAge = this.options['minAge'] || 0;
    this.maxDate.subtract(this.minAge, 'years');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: Date): void {
    const validators = [dateValidatorMax(this.maxDate)];
    if (this.required) validators.push(Validators.required);
    this.formControl.setValidators(validators);
    this.value = moment(value);
  }
}
