import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Moment} from 'moment';
import * as moment from 'moment';

@Component({
    selector: 'app-start-dates',
    templateUrl: './start-dates.component.html',
    styleUrls: ['./start-dates.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StartDatesComponent),
            multi: true
        }
    ]
})
export class StartDatesComponent implements ControlValueAccessor, OnInit {
    @Input() required: boolean;
    @Input() options: any;
    @Input() formControl: FormControl;
    @Input() formControlName: string;
    @Input() formGroup: FormGroup;

    insuranceDataFormGroup: FormGroup;
    dropdownName: string = 'start_date_dropdown';
    now = moment();

    constructor() {
    }

    ngOnInit() {
        this.insuranceDataFormGroup = <FormGroup>this.formControl.parent;
        this.options.entries
            .forEach(item => {
                this.insuranceDataFormGroup.addControl(item.name, new FormControl( this.now));
            });
        this.insuranceDataFormGroup.get(this.formControlName).setValue(this.now);
        this.insuranceDataFormGroup.addControl(this.dropdownName, new FormControl(true));

        this.insuranceDataFormGroup.get(this.formControlName).valueChanges.subscribe((value: Moment) => {
            if (value && value.toDate !== undefined) {
                this.options.entries.forEach(item => {
                    this.insuranceDataFormGroup.get(item.name).setValue(value.toDate());
                    this.insuranceDataFormGroup.get(item.name).updateValueAndValidity();
                });
            }
        });
    }

    registerOnChange(fn: any): void {
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
    }

    private getValue(val) {
        return this.formGroup.parent.get(val) ? this.formGroup.parent.get(val).value : null;
    }

    private isVisible(dependencies: any) {
        return (!dependencies) ? true : !!this.getValue(dependencies[0]);
    }
}
