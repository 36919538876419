import {Injectable} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';


export class ZipCode {
    constructor(public zip: string, public fire: boolean) {
    }
}

export class InsuranceItem {
    constructor(public name: string, public address: string, public zip: string, public city: string) {
    }
}

@Injectable({
    providedIn: 'root'
})
export class FormService {
    warning = new Subject<boolean>();
    price = new Subject<number>();
    final_amount = new Subject<number>();
    total_payable_amount = 0;

    private _items: any[] = [];
    private _fees: any[] = [];
    private warnings: any[] = [];

    private zipCodes: ZipCode[] = [];
    private insuranceItems: InsuranceItem[] = [];
    private bodyMap = {};

    public isFire: boolean[] = [];
    public fireFee: number[] = [];

    public fee = 0.05;
    public discount = 0.00;

    constructor(private httpService: HttpClient) {
        this.httpService.get('./assets/OneNowInsurance/insurances.json').subscribe((result: InsuranceItem[]) => this.insuranceItems = result);
        this.httpService.get('./assets/OneNowInsurance/zipcodes.json').subscribe((result: ZipCode[]) => this.zipCodes = result);
        this.httpService.get('./assets/OneNowInsurance/valuemap.json').subscribe(value => this.bodyMap = value);

        this.isFire.push(false, false);
        this.fireFee.push(0, 0);
    }

    get items(): any[] {
        return this._items;
    }

    set items(value: any[]) {
        this._items = value;
        this.price.next(this.getPrice());
    }

    get fees(): any[] {
        return this._fees;
    }

    set fees(value: any[]) {
        this._fees = value;
        this.price.next(this.getPrice());
    }

    getPrice(): number {
        let sum = 0;
        let fire_fee = 0;
        let discount_amount = 0;

        this._items.forEach(item => {
            fire_fee += item.houses ? item.houses.map(t => t['fire_fee']).reduce((acc, value) => acc + value, 0) : 0;
            discount_amount -= item.discount_amount || 0;
            sum += item.nett_amount - fire_fee + discount_amount;
        });
        return sum;
    }

    search(filter: number): Observable<ZipCode[]> {
        const regex = new RegExp('^' + filter, 'gm');
        return of(this.zipCodes).pipe(map((value: ZipCode[]) => value.filter(v => v.zip.match(regex))));
    }

    searchInsurance(filter: string): Observable<InsuranceItem[]> {
        const regex = new RegExp('^' + filter, 'gmi');
        return of(this.insuranceItems).pipe(map((value: InsuranceItem[]) => value.filter(v => v.name.match(regex))));
    }

    get fireCosts(): number {
        return this.isFire.includes(true) ? 0.01 : 0.00;
    }

    showWarning(name) {
        this.warnings[name] = true;
        this.warning.next(this.warnings.includes(true));
    }

    hideWarning(name) {
        this.warnings[name] = false;
        this.warning.next(this.warnings.includes(true));
    }


    mapValue(value: any) {
        return this.bodyMap[value] ? this.bodyMap[value] : value;
    }

    /*getMainItems() {
        const mainItems = new Map<string, PriceItem>();
        const mainItemsArray = [];

        this.items.map((item: PriceItem) => {
          const parent = item.parent || item.key
          if (mainItems.has(parent)) {
            mainItems.get(parent).cost = +mainItems.get(parent).cost + item.cost
          } else {
            mainItems.set(parent, new PriceItem(item.item, item.cost, item.key, item.parent));
          }
        });

        mainItems.forEach(item => mainItemsArray.push(item));

        return mainItemsArray;
    }*/

    /*addPriceItem(item: PriceItem) {
        this._items.push(item);
        this.price.next(this.getPrice());
    }

    setPriceItem(item: PriceItem) {
        console.log('set', item.key);
        const priceItem = this._items.find( (_item: PriceItem) => _item.key === item.key);
        if(priceItem) {
            priceItem.cost = item.cost;
        } else {
            this.addPriceItem(item);
        }

        this.price.next(this.getPrice());
    }

    removePriceItemByName(name: string) {
        this._items = this._items.filter(items => items.item !== name);
        this.price.next(this.getPrice());
    }

    removePriceItemByKey(key: string) {
        console.log('remove', key);
        this._items = this._items.filter(items => items.key !== key);
        this.price.next(this.getPrice());
    }

    resetPriceItems() {
        this._items.length = 0;
    }*/
}
