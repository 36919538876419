import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true
        }
    ]
})

export class CheckboxComponent implements ControlValueAccessor {
    @Input() required: boolean;
    @Input() options: any[];
    @Input() formControl: FormControl;

    isVisible: boolean = false;

    value: boolean;
    disabled: boolean;
    onChange: any = () => {};
    onTouched: any = () => {};

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: boolean): void {
        this.value = !value ? null : true;
    }

    onToggle() {
        this.value = !this.value ? true : null;
        this.onChange(this.value);
    }

}
