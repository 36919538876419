import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {slideInOut} from '../components.component';
import {ApiService} from '../../services/api.service';

@Component({
    selector: 'app-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleComponent),
            multi: true
        }
    ],
    animations: [slideInOut]
})

export class ToggleComponent implements ControlValueAccessor, OnInit {
    @Input() required: boolean;
    @Input() options: any[];
    @Input() formControl: FormControl;

    isVisible: false;

    value: any;
    disabled: boolean;

    public min: number;
    public max: number;
    public steps: number;
    public insurance: number;
    private name: string;
    private costs: number;

    onChange: any = () => {
    };
    onTouched: any = () => {
    };

    constructor(private apiService: ApiService) {

    }

    ngOnInit() {
        this.min = this.options['insurance'] || 0;
        this.max = this.options['max'] || 1000000;
        this.steps = this.options['steps'] || 100;
        this.insurance = this.options['insurance'] || 0;
        this.costs = this.options['costs'];

        const formGroup = this.formControl.parent.controls;
        this.name = Object.keys(formGroup).find(name => this.formControl === formGroup[name]) || null;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: boolean): void {
        this.value = value || false;
    }

    // onToggle() {
    onToggle(toggle: boolean = true) {
        if (toggle) this.value = !this.value;
        // this.value = !this.value;

        if (this.value && this.insurance && this.options['costs']) {
            this.formControl.setValue({[this.name]: this.value, costs: this.costs, insurance: this.insurance});
        }
        else if (this.value && this.options['second'] && this.options['second']['value']) {
            this.formControl.setValue({[this.name]: this.value, [this.options['second']['name']]: this.options['second']['value']});
        } else {
            if (this.options['second']) this.options['second']['value'] = false;
            this.formControl.setValue(!!this.value);
        }

        if (this.options['callFunction']) this.apiService[this.options['callFunction']]();
        this.onChange(this.value);
    }

    onInputChange(insurance: number, sum = true) {
        this.costs = sum ? this.costs + this.options['costs'] : this.costs - this.options['costs'];
        this.insurance = +insurance;
        this.formControl.setValue({[this.name]: this.value, costs: this.costs, insurance: this.insurance});
        if (this.options['callFunction']) this.apiService[this.options['callFunction']]();
        this.onChange(this.value);
    }
}
