import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {slideInOut} from '../components.component';
import {ApiService} from '../../services/api.service';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownComponent),
            multi: true
        }
    ],
    animations: [slideInOut]
})

export class DropdownComponent implements ControlValueAccessor {
    @Input() required: boolean;
    @Input() options: any[];
    @Input() formControl: FormControl;

    isVisible: boolean = false;

    value: string | boolean;
    disabled: boolean;
    onTouched: () => void;
    onChange: any = () => {};

    constructor(private apiService: ApiService) {

    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: string): void {
        this.value = value === '' ? null : value;
    }

    onSelectionChange() {
        if (this.options['callFunction']) this.apiService[this.options['callFunction']]();
        this.onChange(this.value);
    }
}
