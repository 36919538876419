import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
    MAT_DATE_LOCALE,
    MatButtonModule, MatIconModule, MatMenuModule,
    MatProgressBarModule, MatProgressSpinnerModule,
    MatSnackBarModule,
    MatStepperModule,
    MatToolbarModule
} from '@angular/material';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {ComponentsModule} from './components/components.module';
import {AuthInterceptor} from './auth.interceptor';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        ComponentsModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatStepperModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatIconModule,
        MatMenuModule,
        RouterModule.forRoot([]),
    ],
    providers: [
      {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
      {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
