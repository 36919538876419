import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentsComponent} from './components.component';
import {TextComponent} from './text/text.component';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule, MatDatepickerModule,
    MatGridListModule, MatIconModule,
    MatInputModule, MatProgressSpinnerModule, MatSelectModule,
    MatSlideToggleModule, MatTableModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DateComponent} from './date/date.component';
import {BirthdayComponent} from './birthday/birthday.component';
import {EmailComponent} from './email/email.component';
import {ToggleComponent} from './toggle/toggle.component';
import {DropdownComponent} from './dropdown/dropdown.component';
import {TableComponent} from './table/table.component';
import {SummaryComponent} from './summary/summary.component';
import {CurrencyComponent} from './currency/currency.component';
import {PostCodeComponent} from './postcode/postcode.component';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {RadioComponent} from './radio/radio.component';
import {CheckboxComponent} from './checkbox/checkbox.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {QuestionButtonComponent} from './question-button/question-button.component';
import {InfoComponent} from './info/info.component';
import {StartDatesComponent} from './start-dates/start-dates.component';
import {InsuranceComponent} from './insurance/insurance.component';
import {DownloadComponent} from './download/download.component';
import {UiSwitchModule} from 'ngx-toggle-switch';
import {PhoneNumberComponent} from './phone-number/phone-number.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatSlideToggleModule,
        MatInputModule,
        MatSelectModule,
        MatMomentDateModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatGridListModule,
        MatCheckboxModule,
        FlexLayoutModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        UiSwitchModule
    ],
    declarations: [
        ComponentsComponent,
        TextComponent,
        DateComponent,
        BirthdayComponent,
        EmailComponent,
        ToggleComponent,
        DropdownComponent,
        TableComponent,
        SummaryComponent,
        PostCodeComponent,
        CurrencyComponent,
        RadioComponent,
        CheckboxComponent,
        QuestionButtonComponent,
        InfoComponent,
        StartDatesComponent,
        InsuranceComponent,
        DownloadComponent,
        PhoneNumberComponent
    ],
    exports: [
        ComponentsComponent
    ]
})
export class ComponentsModule {
}
