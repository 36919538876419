import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {debounceTime, finalize, switchMap, tap} from 'rxjs/operators';
import {FormService, ZipCode} from '../../services/form.service';
import {EMPTY} from 'rxjs';
import {ApiService} from '../../services/api.service';

@Component({
    selector: 'app-postcode',
    templateUrl: './postcode.component.html',
    styleUrls: ['./postcode.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PostCodeComponent),
            multi: true
        }
    ]
})

export class PostCodeComponent implements ControlValueAccessor, OnInit {
    @Input() required: boolean;
    @Input() options: any[];
    @Input() formControl: FormControl;

    filteredZipCodes: ZipCode[] = [];

    value: string;
    fire = false;
    disabled: boolean;
    numbers: number;
    min: number;
    max: number;
    isLoading = false;

    onTouched: () => void;
    onChange: any = () => {
    };

    constructor(private formService: FormService, private apiService: ApiService) {
    }

    ngOnInit() {
        this.numbers = this.options['numbers'] || 5;
        this.min = this.options['min'] || 1000;
        this.max = this.options['max'] || 99999;

        this.formControl.valueChanges.pipe(
            debounceTime(300),
            tap(() => this.isLoading = true),
            switchMap(value => {
                if (value >= 1) {
                    return this.formService.search(value).pipe(finalize(() => this.isLoading = false));
                } else {
                    this.isLoading = false;
                    this.filteredZipCodes = [];
                    return EMPTY;
                }
            })
        ).subscribe(zipCodes => {
            if (zipCodes.length === 1) {
                this.fire = zipCodes[0].fire;
                this.formService.isFire[this.options['building']] = this.fire;
            }
            this.filteredZipCodes = zipCodes;
        });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: string): void {
        const validators = [Validators.pattern(`^(\\d{${this.numbers}}?|[A-Z]\\d[A-Z] *\\d[A-Z]\\d)$`), Validators.min(this.min), Validators.max(this.max)];
        if (this.required) validators.push(Validators.required);
        this.formControl.setValidators(validators);
        this.value = value || '';
    }

    displayFn(zipCode: ZipCode) {
        if (zipCode) {
            return zipCode.zip;
        }
    }
}
