import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {FormGroup} from '@angular/forms';
import {FormService} from './form.service';
import * as _moment from 'moment';
import {MatSnackBar} from '@angular/material';
import {Subject} from 'rxjs';

import {environment} from '../../environments/environment';

const moment = _moment;

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private baseUrl = environment.baseUrl;
    private apiVersion = 'v2';

    private apiIds: {} = {};


    private _apiToken = environment.token;
    private _identifier = '';
    private _agent = '';

    private _formGroup: FormGroup;

    public formService: FormService;
    formData: any[] = [];

    profile_picture = new Subject<string>();
    pdf_file = new Subject<string>();

    constructor(private http: HttpClient, private snackBar: MatSnackBar) {
    }

    set agent(agent: string) {
        this._agent = agent;
    }

    get agent() {
        return this._agent;
    }

    get formGroup(): FormGroup {
        return this._formGroup;
    }

    set formGroup(value: FormGroup) {
        this._formGroup = value;
    }

    get apiToken(): string {
        return this._apiToken;
    }

    set apiToken(value: string) {
        this._apiToken = value;
    }

    get identifier(): string {
        return this._identifier;
    }

    set identifier(value: string) {
        this._identifier = value;
    }

    authenticate() {
        // this.http.post(this.baseUrl + 'auth/', {username: 'web_client', password: 'Onenow2018!'}).subscribe(
        //     (result) => this.apiToken = result['token'],
        //     (error) => console.log(error)
        // );
    }

    resetApiIds() {
        this.apiIds = {};
    }

    private getDefaultStartDate() {
        return moment().format('YYYY-MM-DD');
    }

    getAgent(referrer_uuid) {
        this.http.get(this.baseUrl + this.apiVersion + '/partners/' + referrer_uuid + '/').subscribe(
            (result) => {
                console.log(result['message']);
                this.agent = result['data']['uuid'];
                if (result['data']['profile_picture']) {
                    this.profile_picture.next(result['data']['profile_picture']);
                }
            },
            (error) => console.log(error['statusText'])
        );
    }

    step1() {
        const prospect_uuid = this.getProspectUuid();
        const quotation_uuid = this.getUuid('quotation');

        if (!prospect_uuid || !quotation_uuid) {
            return;
        }

        const body = {'items_insured': [], 'submit': true};

        const personal_liability_uuid = this.getUuid('personal_liability');
        const household_uuid = this.getUuid('household');
        const property_uuid = this.getUuid('property');
        const assistance_uuid = this.getUuid('assistance');

        // personal_liability
        if (this.getValue('contact details.selected insurances.private')) {
            if (!personal_liability_uuid) {
                body.items_insured.push(
                    {
                        'name': 'personal_liability',
                        'start_date': this.getDefaultStartDate(),
                        'attributes': [
                            {key: 'ownership', value: 'proprietor', key_display: 'Wohnverhältnis', value_display: 'proprietor'},
                            {key: 'coverage_type', value: 'single', key_display: 'Abdeckung', value_display: 'Privatabdeckung'}
                        ]
                    }
                );
            }
        } else if (personal_liability_uuid) {
            body.items_insured.push(
                {
                    'uuid': personal_liability_uuid,
                    'remove': true
                }
            );
        }

        // household
        if (this.getValue('contact details.selected insurances.household')) {
            if (!household_uuid) {
                body.items_insured.push({
                    'name': 'household',
                    'start_date': this.getDefaultStartDate(),
                    'attributes': [
                        {
                            'key': 'houses',
                            'value': [
                                [
                                    {key: 'sum_insured', key_display: 'Wert Ihres Hausrats', value: 1000, value_display: '1000'},
                                    {key: 'construction_type', key_display: 'Besteht das Gebäude, in dem Ihr Hausrat steht aus Beton?', value: 'massive', value_display: 'massiv'}
                                ]
                            ],
                            'key_display': 'Hausratversicherung',
                            'value_display': '1 Haus'
                        }
                    ]
                });
            }
        } else if (household_uuid) {
            body.items_insured.push(
                {
                    'uuid': household_uuid,
                    'remove': true
                }
            );
        }

        // building
        if (this.getValue('contact details.selected insurances.building')) {
            if (!property_uuid) {
                body.items_insured.push({
                    'name': 'property',
                    'start_date': this.getDefaultStartDate(),
                    'attributes': [
                        {
                            'key': 'houses',
                            'value': [
                                [
                                    {key: 'gvg', key_display: 'GVG', value: 10000, value_display: '10000'},
                                    {key: 'sum_insured', value: 50000, key_display: 'Wert Ihres Gebäudes', value_display: '50000'},
                                    {key: 'zip_code', value: 4000, key_display: 'Postleitzahl des Gebäudes', value_display: '4000'},
                                    {key: 'construction_type', value: 'massive', key_display: 'Besteht Ihr Gebäude aus Beton', value_display: 'massiv'},
                                    {key: 'roof_type', value: 'non-flat', key_display: 'Hat Ihr Gebäude ein Flachdach', value_display: 'nicht flach'},
                                    {key: 'stay_type', value: 'residence', key_display: 'Nutzen Sie Ihr Gebäude als Wohn- oder Ferienhaus', value_display: 'Wohnhaus'}
                                ]
                            ],
                            'key_display': 'Gebäudeversicherung',
                            'value_display': '1 Haus'
                        }
                    ]
                });
            }
        } else if (property_uuid) {
            body.items_insured.push(
                {
                    'uuid': property_uuid,
                    'remove': true
                }
            );
        }

        // travel
        if (this.getValue('contact details.selected insurances.travel')) {
            if (!assistance_uuid) {
                body.items_insured.push({
                    'name': 'assistance',
                    'sum_insured': 0,
                    'start_date': this.getDefaultStartDate(),
                    'attributes': [
                        {key: 'coverage_type', value: 'single', key_display: 'Abdeckung', value_display: 'Privatabdeckung'},
                        {key: 'cancellation', value: true, key_display: 'Annullation', value_display: 'Ja'},
                        {key: 'personal-assistance', value: true, key_display: 'Personen-Assistance', value_display: 'Ja'},
                        {key: 'vehicle-assistance', value: true, key_display: 'Pannenhilfe', value_display: 'Ja'}
                    ]
                });
            }
        } else if (assistance_uuid) {
            body.items_insured.push(
                {
                    'uuid': assistance_uuid,
                    'remove': true
                }
            );
        }
        if (body.items_insured.length) {
            this.http.patch(this.baseUrl + this.apiVersion + '/quotations/' + quotation_uuid + '/', body).subscribe(
                (result) => {
                    console.log(result['message']);

                    this.deleteUuid('personal_liability');
                    this.deleteUuid('household');
                    this.deleteUuid('property');
                    this.deleteUuid('assistance');

                    result['data']['items_insured'].forEach((item) => this.setUuid(item.uuid, item.name));
                    this.rateQuotation();
                },
                (error: HttpErrorResponse) => console.log(error.message)
            );
        }
    }

    step2() {
        let prospect_uuid = this.getProspectUuid();
        let quotation_uuid = this.getUuid('quotation');

        let dob = this.getValue('contact details.user data.dob');

        if (typeof dob === 'string') {
            dob = moment(dob);
        }

        const prospectBody = {
            'first_name': this.getValue('contact details.user data.first_name'),
            'last_name': this.getValue('contact details.user data.last_name'),
            'email_primary': this.getValue('contact details.user data.email_primary'),
            'phone_mobile': this.getValue('contact details.user data.phone_mobile') || 'none',
            'dob': dob.format('YYYY-MM-DD')
        };

        this.http[prospect_uuid ? 'patch' : 'post'](this.baseUrl + this.apiVersion + '/partners/prospects/' + (prospect_uuid ? prospect_uuid + '/' : ''), prospectBody).subscribe(
            (result) => {
                console.log(result['message']);

                prospect_uuid = result['data']['uuid'] || prospect_uuid;
                if (result['data']['addresses']) {
                    result['data']['addresses'].forEach((item, key) => this.setUuid(item.uuid, 'address_' + key));
                }
                if (prospect_uuid) {
                    this.setProspectUuid(prospect_uuid);

                    if (!quotation_uuid) {
                        const quotationBody = {
                            'holder': prospect_uuid,
                            'product': environment.product_uuid,
                            'payment_scheme': 2,
                            'policy_term': 3,
                            'recurrence_scheme': 1,
                            'submit': true
                        };

                        if (this.agent !== '') {
                            quotationBody['agent'] = this.agent;
                        }

                        this.http.post(this.baseUrl + this.apiVersion + '/quotations/', quotationBody).subscribe(
                            (ret_val) => {
                                console.log(ret_val['message']);
                                quotation_uuid = ret_val['data']['uuid'] || false;
                                if (quotation_uuid) {
                                    this.setUuid(quotation_uuid, 'quotation');
                                    this.step1();
                                }
                            }, (error: HttpErrorResponse) => console.log(error.message)
                        );
                    }
                }
            },
            (error: HttpErrorResponse) => {
                if (error.error.message === 'BusinessPartner Not Found!') {
                    localStorage.removeItem(this.identifier);
                    this.step2();
                }
            }
        );
    }

    step3b() {
        const ownership = this.getValue('insurance details.personal details.resident status');
        const coverage_type = this.getValue('insurance details.personal details.marital status');

        const body = {'items_insured': [], 'submit': true};

        if (this.getValue('contact details.selected insurances.private') && this.getUuid('personal_liability')) {

            body.items_insured.push(
                {
                    'uuid': this.getUuid('personal_liability'),
                    'attributes': [
                        {
                            'key': 'ownership',
                            'value': ownership,
                            'key_display': this.formService.mapValue('ownership'),
                            'value_display': this.formService.mapValue(ownership)

                        },
                        {
                            'key': 'coverage_type',
                            'value': coverage_type,
                            'key_display': this.formService.mapValue('coverage_type'),
                            'value_display': this.formService.mapValue(coverage_type)

                        }
                    ],
                }
            );


            this.getDataValue('insurance details.private details').fields.forEach((field) => {
                const value = this.getValue('insurance details.private details.' + field['name']);
                if (value) {
                    body.items_insured[0]['attributes'].push(
                        {
                            'key': field['name'],
                            'value': this.prepareValue(value),
                            'key_display': field['options']['label'],
                            'value_display': this.formService.mapValue(value)
                        }
                    );
                }
            });
        }

        if (this.getValue('contact details.selected insurances.travel') && this.getUuid('assistance')) {
            body.items_insured.push(
                {
                    'uuid': this.getUuid('assistance'),
                    'sum_insured': 0,
                    'attributes': [
                        {
                            'key': 'coverage_type',
                            'value': coverage_type,
                            'key_display': this.formService.mapValue('coverage_type'),
                            'value_display': this.formService.mapValue(coverage_type)

                        },
                        {
                            'key': 'cancellation',
                            'value': true,
                            'key_display': 'Annullation',
                            'value_display': 'Ja'
                        },
                        {
                            'key': 'personal-assistance',
                            'value': true,
                            'key_display': 'Personen-Assistance',
                            'value_display': 'Ja'
                        },
                        {
                            'key': 'vehicle-assistance',
                            'value': true,
                            'key_display': 'Pannenhilfe',
                            'value_display': 'Ja'
                        }
                    ]
                }
            );
        }
        this.patchQuotation(body);
    }

    step3c() {
        if ((this.getValue('contact details.selected insurances.household') || this.getValue('contact details.selected insurances.household.household')) && this.getUuid('household')) {
            const body = {
                'items_insured': [{
                    'uuid': this.getUuid('household'), 'attributes': [
                        {
                            'key': 'houses',
                            'value': [
                                []
                            ],
                            'key_display': 'Hausratversicherung',
                            'value_display': '1 Haus'
                        }
                    ]
                }], 'submit': true
            };

            this.getDataValue('insurance details.household details').fields.filter(item => item.type !== 'info').forEach((field) => {
                const value = this.getValue('insurance details.household details.' + field['name'] + '.insurance') || this.getValue('insurance details.household details.' + field['name']);
                if (value) {
                    body.items_insured[0]['attributes'][0]['value'][0].push(
                        {
                            'key': field['name'],
                            'value': this.prepareValue(value),
                            'key_display': field['options']['label'],
                            'value_display': this.formService.mapValue(value)
                        }
                    );
                }
            });

            if (this.getValue('contact details.selected insurances.household.second household')) {
                body.items_insured[0]['attributes'][0]['value_display'] = '2 Häuser';
                this.getDataValue('insurance details.second household details').fields.filter(item => item.type !== 'info').forEach((field) => {
                    const value = this.getValue('insurance details.second household details.' + field['name'] + '.insurance') || this.getValue('insurance details.second household details.' + field['name']);
                    if (value) {
                        if (!body.items_insured[0]['attributes'][0]['value'][1]) {
                            body.items_insured[0]['attributes'][0]['value'].push([]);
                        }

                        body.items_insured[0]['attributes'][0]['value'][1].push(
                            {
                                'key': field['name'],
                                'value': this.prepareValue(value),
                                'key_display': field['options']['label'],
                                'value_display': this.formService.mapValue(value)
                            }
                        );
                    }
                });
            }

            this.patchQuotation(body);
        }
    }

    step3d() {
        if ((this.getValue('contact details.selected insurances.building') || this.getValue('contact details.selected insurances.building.building')) && this.getUuid('property')) {
            const body = {
                'items_insured': [{
                    'uuid': this.getUuid('property'), 'attributes': [
                        {
                            'key': 'houses',
                            'value': [
                                [
                                    {
                                        'key': 'gvg',
                                        'key_display': 'GVG',
                                        'value': 10000,
                                        'value_display': '10000'
                                    }
                                ]
                            ],
                            'key_display': 'Gebäudeversicherung',
                            'value_display': '1 Haus'
                        }
                    ]
                }], 'submit': true
            };

            this.getDataValue('insurance details.building details').fields.filter(item => item.type !== 'info').forEach((field) => {
                const value = this.getValue('insurance details.building details.' + field['name'] + '.insurance') || this.getValue('insurance details.building details.' + field['name']);
                if (value) {
                    body.items_insured[0]['attributes'][0]['value'][0].push(
                        {
                            'key': field['name'],
                            'value': this.prepareValue(value),
                            'key_display': field['options']['label'],
                            'value_display': this.formService.mapValue(value)
                        }
                    );
                }
            });

            if (this.getValue('contact details.selected insurances.building.second building')) {
                body.items_insured[0]['attributes'][0]['value_display'] = '2 Häuser';
                this.getDataValue('insurance details.second building details').fields.filter(item => item.type !== 'info').forEach((field) => {
                    const value = this.getValue('insurance details.second building details.' + field['name'] + '.insurance') || this.getValue('insurance details.second building details.' + field['name']);
                    if (value) {
                        if (!body.items_insured[0]['attributes'][0]['value'][1]) {
                            body.items_insured[0]['attributes'][0]['value'].push([{
                                'key': 'gvg',
                                'key_display': 'GVG',
                                'value': 10000,
                                'value_display': '10000'
                            }]);
                        }
                        body.items_insured[0]['attributes'][0]['value'][1].push(
                            {
                                'key': field['name'],
                                'value': this.prepareValue(value),
                                'key_display': field['options']['label'],
                                'value_display': this.formService.mapValue(value)
                            }
                        );
                    }
                });

                body.items_insured[0]['attributes'][0]['value'][0].push(
                    {
                        'key': 'gvg',
                        'key_display': 'GVG',
                        'value': 10000,
                        'value_display': '10000'
                    }
                );
            }

            this.patchQuotation(body);
        }
    }

    patchMainAddress() {
        const body = {
            'addresses': [
                {
                    'line1': this.getValue('contract.main address data.address'),
                    'postal_code': this.getValue('contract.main address data.postcode'),
                    'city': this.getValue('contract.main address data.city')
                }
            ]
        };

        if (this.getUuid('address_0')) {
            body['addresses'][0]['uuid'] = this.getUuid('address_0');
        }

        if (this.getValue('contract.second address data.address')) {
            body.addresses.push(
                {
                    'line1': this.getValue('contract.second address data.address'),
                    'postal_code': this.getValue('contract.second address data.postcode'),
                    'city': this.getValue('contract.second address data.city')
                }
            );
            if (this.getUuid('address_1')) {
                body['addresses'][1]['uuid'] = this.getUuid('address_1');
            }
        }

        this.http.patch(this.baseUrl + this.apiVersion + '/partners/prospects/' + this.getProspectUuid() + '/', body).subscribe(
            result => {
                if (result['data']['addresses']) {
                    result['data']['addresses'].forEach((item, key) => this.setUuid(item.uuid, 'address_' + key));
                }
                console.log(result['message']);
            },
            error => console.log(error)
        );
    }


    patchStartDates() {
        const body = {
            'items_insured': [], 'submit': true
        };

        // personal_liability
        if (this.getValue('contact details.selected insurances.private') && this.getUuid('personal_liability')) {
            const date = moment(this.getValue('contract.insurance data.date_private')).format('YYYY-MM-DD');
            body['items_insured'].push({'uuid': this.getUuid('personal_liability'), 'start_date': date});
        }

        // assistance
        if (this.getValue('contact details.selected insurances.travel') && this.getUuid('assistance')) {
            const date = moment(this.getValue('contract.insurance data.date_travel')).format('YYYY-MM-DD');
            body['items_insured'].push({'uuid': this.getUuid('assistance'), 'start_date': date});
        }

        // household
        if ((this.getValue('contact details.selected insurances.household') || this.getValue('contact details.selected insurances.household.household')) && this.getUuid('household')) {
            const date = moment(this.getValue('contract.insurance data.date_household')).format('YYYY-MM-DD');
            body['items_insured'].push({'uuid': this.getUuid('household'), 'start_date': date});
        }

        // building
        if ((this.getValue('contact details.selected insurances.building') || this.getValue('contact details.selected insurances.building.building')) && this.getUuid('property')) {
            const date = moment(this.getValue('contract.insurance data.date_building')).format('YYYY-MM-DD');
            body['items_insured'].push({'uuid': this.getUuid('property'), 'start_date': date});
        }

        if (body['items_insured'].length) {
            this.patchQuotation(body);
        }
    }

    private patchQuotation(body) {
        if (this.getUuid('quotation')) {
            this.http.patch(this.baseUrl + this.apiVersion + '/quotations/' + this.getUuid('quotation') + '/', body).subscribe(
                (result) => {
                    console.log(result['message']);
                    this.rateQuotation();
                }, (error: HttpErrorResponse) => console.log(error.message)
            );
        } else {
            console.log('Unable to patch quotation');
        }

        this.formService.fireFee[0] = this.getValue('insurance details.household details.sum_insured') ? this.prepareValue(this.getValue('insurance details.household details.sum_insured')) : 0;
        this.formService.fireFee[1] = this.getValue('insurance details.second household details.sum_insured') ? this.prepareValue(this.getValue('insurance details.second household details.sum_insured')) : 0;
    }

    sendOffer() {
        this.http.post(this.baseUrl + 'v1/policies/send-email/', {'action': 'incomplete', 'uuid': this.getUuid('quotation')}).subscribe(
            result => console.log(result['message']),
            error => console.log(error));
    }

    rateQuotation() {
        // const uuid = 'dbaf65d3-35c8-4dd9-bb89-da2643981fc3'; // this.getUuid('quotation');
        // const uuid = '6668ef11-88be-44c9-9bb1-3051dc7f7a43'; // this.getUuid('quotation');
        const uuid = this.getUuid('quotation');
        if (uuid) {
            this.http.post(this.baseUrl + this.apiVersion + '/quotations/' + uuid + '/rate/', {'submit': true}).subscribe(
                (result) => {
                    console.log(result['message']);
                    this.formService.items = Array.isArray(result['data']['rating_payload']) ? result['data']['rating_payload'] : [];
                    this.formService.final_amount.next(result['data']['final_amount']);
                    this.formService.total_payable_amount = result['data']['total_payable_amount'];
                    this.formService.fees = result['data']['pricing_breakdown'] ? result['data']['pricing_breakdown'] : [];
                }, (error: HttpErrorResponse) => console.log(error.message)
            );
        } else {
            console.log('Unable to rate quotation');
        }
    }

    createCreditPayment(pay_load) {
        const message = 'Police erstellt. Ihre Zahlung war erfolgreich.';
        const error_msg = 'Police konnte nicht erstellt werden. Bitte kontaktieren Sie unseren Support unter support@onenow.ch oder versuchen Sie es später noch einmal.';
        const body = {
            'quotation': this.getUuid('quotation'),
            // 'amount': this.formService.total_payable_amount,
            'amount': parseFloat((this.formService.total_payable_amount * 100).toFixed(2)),
            'description': 'Policy Charge I2go',
            'currency': 'CHF',
            'card_number_mask': pay_load.card.last4,
            'card_type': pay_load.card.funding,
            'card_issuer': pay_load.card.type,
            'source': pay_load.id,
            'card_issuer_country': pay_load.card.address_country || 'ch',
            'card_issuer_country_code': pay_load.card.country,
            'flag': true
        };

        this.http.post(this.baseUrl + 'v1/payments/strip-payment-token/', body).subscribe(
            (result) => {
                if (result['message'] === 'Submitted!') {
                    this.pdf_file.next(result['pdf_file']);
                    this.snackBar.open(message, 'OK', {duration: 0});
                } else {
                    this.snackBar.open(error_msg, 'OK', {duration: 0});
                }
            },
            () => this.snackBar.open(error_msg, 'OK', {duration: 0}),
            () => localStorage.removeItem(this.identifier)
        );
    }

    createBillingPayment() {
        const message = 'Police erstellt. Bitte bezahlen Sie ihre Rechnung innerhalb von 20 Tagen.';
        const body = {
            'quotation': this.getUuid('quotation'),
            'submit': true,
            'transaction': {
                'amount_received': this.formService.total_payable_amount,
                'payment_method': 'Cash',
                'partner': this.getProspectUuid()
            }
        };

        this.http.post(this.baseUrl + this.apiVersion + '/policies/', body).subscribe(
            (result) => {
                this.pdf_file.next(result['data']['pdf_file']);
                this.snackBar.open(message, 'OK', {duration: 0});
            },
            () => this.snackBar.open('Police konnte nicht erstellt werden. Bitte kontaktieren Sie unseren Support unter support@onenow.ch oder versuchen Sie es später noch einmal.', 'OK', {duration: 0}),
            () => localStorage.removeItem(this.identifier)
        );
    }


    private prepareValue(value: any) {
        switch (true) {
            case value === 'true' || value === true:
                return true;
            case value === 'false' || value === false:
                return false;
            case !isNaN(value):
                return parseInt(value, 10);
            /*case moment(value) instanceof moment:
                return (<Moment>value).format('YYYY-MM-DD');*/
            default:
                return value;
        }
    }

    private getValue(val, useFormGroup = true) {
        return this.getNested(useFormGroup ? this.formGroup.value : this.formData, val) || null;
    }

    private getDataValue(path, data = this.formData, count = 0) {
        const pathParts = path.split('.');

        let value = null;

        if (pathParts.length < 4) {
            for (let i = 0; i < pathParts.length; i++) {
                if (i === 1) {
                    value = value.categories.find(item => item.name === pathParts[i]);
                } else if (i === 2) {
                    value = value.fields.find(item => item.name === pathParts[i]);
                } else {
                    value = data.find(item => item.name === pathParts[i]);
                }

                if (value === undefined) {
                    value = null;
                    break;
                }
            }
        }
        return value;
    }

    public getNested(theObject, path, separator?) {
        try {
            separator = separator || '.';
            return path.replace('[', separator).replace(']', '').split(separator).reduce((obj, property) => obj[property], theObject);
        } catch (err) {
            return undefined;
        }
    }

    getUuid(key: string) {
        return this.apiIds[key] || false;
    }

    setUuid(uuid: string, key: string) {
        this.apiIds[key] = uuid;

    }

    deleteUuid(key: string) {
        delete this.apiIds[key];
    }

    getProspectUuid() {
        const prospect_uuid = localStorage.getItem(this.identifier);
        return prospect_uuid ? prospect_uuid : false;
    }

    // protected async checkProspectUuid(uuid: string) {
    //     const result = await this.http.get(this.baseUrl + 'v2/partners/prospects/' + uuid + '/').toPromise();
    //     return result["status"] == 200;
    // }

    setProspectUuid(prospect_uuid) {
        localStorage.setItem(this.identifier, prospect_uuid);
    }
}
