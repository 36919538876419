import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {dateValidatorMin} from '../birthday/birthday.component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import * as _moment from 'moment';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {MY_FORMATS} from '../components.component';
import {Moment} from 'moment';

const moment = _moment;

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateComponent),
            multi: true
        },
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ]
})
export class DateComponent implements ControlValueAccessor {
    @Input() required: boolean;
    @Input() options: any[];
    @Input() formControl: FormControl;

    @Input() value: Moment;
    disabled: boolean;
    minDate = moment().startOf('day').hours(0);
    onChange: (value) => void;
    onTouched: () => void;

    isVisible:  false;

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: Moment): void {
        const validators = [dateValidatorMin(this.minDate)];
        if (this.required) validators.push(Validators.required);
        this.formControl.setValidators(validators);
        this.value = value;
    }
}
