import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormControlName, FormGroup, FormGroupName} from '@angular/forms';
import {animate, state, style, transition, trigger} from '@angular/animations';

export const slideInOut = trigger('slideInOut', [
    transition(':enter', [
        style({transform: 'translateY(-100%)', opacity: 0, height: '0px'}),
        animate('300ms ease-in', style({transform: 'translateY(0%)', opacity: 1, height: '100%'}))
    ]),
    transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateY(-100%)', opacity: 0, height: '0px'}))
    ])
]);

export const MY_FORMATS = {
    parse: {
        dateInput: 'L',
    },
    display: {
        dateInput: 'L',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-components',
    templateUrl: './components.component.html',
    styleUrls: ['./components.component.scss'],
    // animations: [
    //     trigger('slideInOut', [
    //         transition(':enter', [
    //             style({transform: 'translateY(-100%)', opacity: 0, height: '0px'}),
    //             animate('300ms ease-in', style({transform: 'translateY(0%)', opacity: 1, height: '100%'}))
    //         ]),
    //         transition(':leave', [
    //             animate('300ms ease-in', style({transform: 'translateY(-100%)', opacity: 0, height: '0px'}))
    //         ])
    //     ])
    // ]
})
export class ComponentsComponent implements OnInit {
    @Input() type: string;
    @Input() options: any[];
    @Input() required: true;

    @Input() form_group: FormGroup;
    @Input() form_control: FormControl;
    @Input() form_group_name: FormGroupName;
    @Input() form_control_name: FormControlName;

    constructor() {
    }

    ngOnInit() {
    }

}
