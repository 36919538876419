import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {slideInOut} from '../components.component';

@Component({
    selector: 'app-currency',
    templateUrl: './currency.component.html',
    styleUrls: ['./currency.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CurrencyComponent),
            multi: true
        }
    ],
    animations: [slideInOut]
})

export class CurrencyComponent implements ControlValueAccessor, OnInit {
    @Input() required: boolean;
    @Input() options: any[];
    @Input() formControl: FormControl;

    value: string;
    disabled: boolean;
    onChange: (value) => void;
    onTouched: () => void;

    isVisible: boolean = false;

    min: number;
    max: number;

    ngOnInit() {
        this.min = this.options['min'] || 1000;
        this.max = this.options['max'] || 1000000;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: string): void {
        const validators = [Validators.pattern(`^[1-9]+[0-9]*`), Validators.min(this.min), Validators.max(this.max)];
        if (this.required) validators.push(Validators.required);
        this.formControl.setValidators(validators);
        this.value = value || '';
    }
}
