import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {debounceTime, finalize, switchMap, tap} from 'rxjs/operators';
import {FormService, InsuranceItem} from '../../services/form.service';
import {EMPTY} from 'rxjs';

@Component({
    selector: 'app-insurance',
    templateUrl: './insurance.component.html',
    styleUrls: ['./insurance.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InsuranceComponent),
            multi: true
        }
    ]
})

export class InsuranceComponent implements ControlValueAccessor, OnInit {
    @Input() required: boolean;
    @Input() options: any[];
    @Input() formControl: FormControl;

    filteredInsuranceItems: InsuranceItem[] = [];

    value: string;
    disabled: boolean;
    isLoading = false;

    onTouched: () => void;
    onChange: any = () => {
    };

    constructor(private formService: FormService) {

    }

    ngOnInit() {
        this.formControl.valueChanges.pipe(
            debounceTime(300),
            tap(() => this.isLoading = true),
            switchMap(value => {
                if (value.length >= 1) {
                    return this.formService.searchInsurance(value).pipe(finalize(() => this.isLoading = false));
                } else {
                    this.isLoading = false;
                    this.filteredInsuranceItems = [];
                    return EMPTY;
                }
            })
        ).subscribe(insuranceItems => {
            this.filteredInsuranceItems = insuranceItems;
        });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: string): void {
        const validators = [];
        if (this.required) validators.push(Validators.required);
        this.formControl.setValidators(validators);
        this.value = value || '';
    }

    displayFn(insuranceItem: InsuranceItem) {
        if (insuranceItem) {
            return insuranceItem.name;
        }
    }
}
