import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormService} from '../../services/form.service';
import {slideInOut} from '../components.component';
import {ApiService} from '../../services/api.service';
import {MatTableDataSource} from '@angular/material';

@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss'],
    animations: [slideInOut]
})

export class SummaryComponent implements OnInit {
    @Input() options: any;
    @Input() formGroup: FormGroup;

    showInfo: false;
    costs: any[] = [];
    additionalCosts: any[] = [];

    constructor(private formService: FormService, private api: ApiService) {
    }

    ngOnInit() {
        // this.api.rateQuotation();
        this.formService.price.subscribe(() => this.initData());
        // this.initData();

        this.formGroup.parent.valueChanges.subscribe(() => this.options.entries.map(entry => entry.val = this.match(entry)));
        this.options.entries.map(entry => entry.val = this.match(entry));
    }

    initData() {

        let discount_amount = 0;
        let total_discount_amount = 0;
        let stamp_duty_amount = 0;
        let fire_fee = 0;
        const pricing = [];

        this.costs = this.formService.items.map((ratingItem) => {
            total_discount_amount -= ratingItem.total_discount_amount || 0;
            stamp_duty_amount += ratingItem.stamp_duty_amount || 0;

            const item_fire_fee = ratingItem.houses ? ratingItem.houses.map(t => t['fire_fee']).reduce((acc, value) => acc + value, 0) : 0;
            const item_discount_amount = ratingItem.discount_amount || 0;

            fire_fee += item_fire_fee;
            discount_amount -= item_discount_amount;

            return {item: ratingItem.product, cost: (ratingItem.nett_amount - item_fire_fee - item_discount_amount)};
        });
        if (total_discount_amount !== 0) {
            pricing.push({item: 'discount', cost: total_discount_amount});
        }
        if (stamp_duty_amount !== 0 || fire_fee !== 0) {
            pricing.push({item: 'fee', cost: stamp_duty_amount + fire_fee});
        }
        this.additionalCosts = pricing;
    }

    getCosts(name) {
        const item = this.costs.find( cost => cost['item'] === name);
        return item && item['cost'] ? item['cost'] : this.getAdditionalCosts(name);
    }

    getAdditionalCosts(name) {
        const item = this.additionalCosts.find( cost => cost['item'] === name);
        return item && item['cost'] ? item['cost'] : 0;
    }

    private match(entry) {
        let match = entry.value.match(/{{(.*?)}}/);
        let ret_val = entry.value;
        const property = entry.property || false;
        ret_val = match ? ret_val.replace(match[0], this.getValueMapped(match[1]) ? (this.getValueMapped(match[1])[property] || this.getValueMapped(match[1])) : '') : ret_val;
        match = ret_val.match(/{{(.*?)}}/);
        return match ? ret_val.replace(match[0], this.getValueMapped(match[1]) ? (this.getValueMapped(match[1])[property] || this.getValueMapped(match[1])) : '') : ret_val;
    }

    private getValue(val) {
        return this.formGroup.parent.get(val) ? this.formGroup.parent.get(val).value : null;
    }

    private getValueMapped(val) {
        return this.formService.mapValue(this.getValue(val));
    }

    private isVisible(dependencies: any) {
        return (!dependencies) ? true : !!this.getValue(dependencies[0]);
    }
}
