import {Component, Input, OnInit} from '@angular/core';
import {FormService} from '../../services/form.service';
import {MatTableDataSource} from '@angular/material';
import {ApiService} from '../../services/api.service';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {

    @Input() options: any[];

    displayedColumns: string[] = ['item', 'cost'];
    transactions = new MatTableDataSource();
    additionalCosts = new MatTableDataSource();


    constructor(private formService: FormService, private api: ApiService) {
        // this.api.rateQuotation();
    }

    ngOnInit() {
        this.formService.price.subscribe(() => this.initTable());
        this.initTable();
    }

    private initTable() {

        let discount_amount = 0;
        let total_discount_amount = 0;
        let stamp_duty_amount = 0;
        let fire_fee = 0;
        const pricing = [];

        this.transactions = new MatTableDataSource(this.formService.items.map((ratingItem) => {
            total_discount_amount -= ratingItem.total_discount_amount || 0;
            stamp_duty_amount += ratingItem.stamp_duty_amount || 0;

            const item_fire_fee = ratingItem.houses ? ratingItem.houses.map(t => t['fire_fee']).reduce((acc, value) => acc + value, 0) : 0;
            const item_discount_amount = ratingItem.discount_amount || 0;

            fire_fee += item_fire_fee;
            discount_amount -= item_discount_amount;

            return {item: this.formService.mapValue(ratingItem.product), cost: (ratingItem.nett_amount - item_fire_fee - item_discount_amount)};
        }));


        if (total_discount_amount !== 0) {
            pricing.push({item: 'Abzüglich Kombinationsrabatt', cost: total_discount_amount});
        }
        if (stamp_duty_amount !== 0) {
            pricing.push({item: 'Zuzüglich Stempelgebühren', cost: stamp_duty_amount});
        }
        if (fire_fee !== 0) {
            pricing.push({item: 'Zuzüglich Feuerlöschkosten', cost: fire_fee});
        }
        this.additionalCosts.data = pricing;
    }

    getTotalCost() {
        return this.transactions.data.map(t => t['cost']).reduce((acc, value) => acc + value, 0);
    }

    getFinalCost() {
        return this.getTotalCost() + this.additionalCosts.data.map(t => t['cost']).reduce((acc, value) => acc + value, 0);
    }

    getPercentage(value) {
        return value / this.getTotalCost();
    }
}
