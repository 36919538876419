import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ApiService} from './services/api.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private apiService: ApiService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Apply the headers
    req = req.clone({
      setHeaders: {
        'Authorization': 'i2go ' + this.apiService.apiToken
      }
    });

    // Also handle errors globally
    return next.handle(req).pipe(
      tap(
          x => x,
          // err => console.error(`Error performing request, status code = ${err.status}`)
      )
    );
  }
}
